import { ICertificationsDataCtx } from './types';

export const certificationsInitialValues: ICertificationsDataCtx = {
  users: null,
  fetching: false,
  fetchingUsers: false,
  fetchingCounters: false,
  fetchingTable: false,
  fetchingEmptyState: false,
  emptyState: false,
  counters: {
    total_vouchers_assigned: 0,
    total_certification_attempts_taken: 0,
    percentage_of_users_with_vouchers: 0,
    total_passed_certification_attempts: 0,
  },
  filters: {
    expiration_date_range: [],
    teams: [],
    user_ids: [],
    certification_short_names: [],
    result: [],
    page: 1,
    page_size: 10,
  },
};
