import { screenSm, screenXs } from 'constants/styles/breakpoints';
import styled, { css } from 'styled-components';

export const NoMobile = styled.div`
  display: none;
  ${screenSm} {
    display: block;
  }
`;

const sharedLoadingStyles = css`
  position: relative;
  height: 100%;
  min-height: 150px;

  ${screenXs} {
    display: grid;
    grid-template-columns: 100%;

    img {
      position: relative;
      left: 50%;
      margin-top: 50px;
      top: 0;
    }
  }
`;

export const Table = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Container: styled.table`
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  Thead: styled.thead<{ height?: string; stickyOffset?: number }>`
    ${({ stickyOffset }) =>
      stickyOffset !== undefined && `position: sticky; top: ${stickyOffset}px;`}
    background-color: #ffffff;
    th {
      height: ${({ height }) => height || '45px'};
    }
    ${screenSm} {
      display: table;
      z-index: 1;
    }
  `,
  Tbody: styled.tbody<{ height?: string }>`
    height: 100%;
    ${screenSm} {
      tr {
        height: ${({ height }) => height || 'auto'};
        min-height: 45px;
      }
    }
  `,
  LoadingWrapper: styled.div`
    ${sharedLoadingStyles}
  `,
  Loading: styled.tr`
    ${sharedLoadingStyles}
  `,
  Tr: styled.tr<{
    borderBottom?: boolean;
    hidden?: boolean;
    isTablet?: boolean;
    columns?: string;
    mobileColumns?: string;
    hasScroll?: boolean;
    expireSoon?: {
      firstRow: boolean;
      middleRow: boolean;
      lastRow: boolean;
    } | null;
  }>`
    width: 100%;
    display: grid;
    grid-template-columns: ${({ mobileColumns, columns }) =>
      mobileColumns || columns || '11.14% 16.88% 17.91% 11.6% 12.17% 17.22% 6.66% 6.43%'};
    gap: 20px;
    padding: 15px 0;

    ${({ hasScroll }) => hasScroll && 'width: calc(100% - 17px);'}
    ${({ hidden }) => hidden && 'display: none;'}

    ${screenSm} {
      display: grid;
      padding: 0 8px 0 10px;

      ${({ columns }) => columns && `grid-template-columns: ${columns};`}

      ${({ borderBottom }) =>
        borderBottom &&
        `
          &:not(:last-child) {
            border-bottom: solid 1px #F2F2F7;
          }
        `}
      
      ${({ isTablet }) => isTablet && 'grid-template-columns: 88% 6% 5%;'}
    }
    ${({ expireSoon }) =>
      expireSoon &&
      `
      background-color: var(--orange-bg);
   
      ${
        expireSoon.firstRow
          ? `
      border: 2px solid var(--egg-orange);  
      border-bottom: none;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    `
          : ''
      }

    ${
      expireSoon.middleRow
        ? `
        border-left: 2px solid var(--egg-orange) !important;
        border-right: 2px solid var(--egg-orange) !important;
      `
        : ''
    }
    
    ${
      expireSoon.lastRow
        ? `
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border: 2px solid var(--egg-orange) !important;  
      border-top: none ${expireSoon.firstRow ? '' : '!important'};
    `
        : ''
    }
  `}
  `,
  Th: styled.th<{ withSort?: boolean; height?: string }>`
    display: flex;
    align-items: center;
    background-color: #ffffff;
    gap: 2px;
    ${({ withSort }) =>
      withSort &&
      `
      button {
        margin-left: 5px;
        width: 26px !important;
        height: 26px !important;
        padding: 0px 5px !important;
      }
    `}
    ${screenSm} {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  `,
  Td: styled.td<{
    hiddenMd?: boolean;
    centerContent?: boolean;
    hidden?: boolean;
    hiddenSm?: boolean;
    withEllipsis?: boolean;
  }>`
    color: var(--black-dark);
    ${({ centerContent }) => centerContent && 'text-align: center;'}
    ${({ hidden }) => hidden && 'display: none;'}
    ${({ withEllipsis }) =>
      withEllipsis &&
      ` white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;`}

    ${screenXs} {
      ${({ hiddenSm }) => (hiddenSm ? 'display: none;' : 'display: block;')}
    }

    ${screenSm} {
      ${({ hiddenMd }) => (hiddenMd ? 'display: none;' : 'display: block;')}
      color: var(--black-dark);
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
      align-self: center;
    }
  `,
  EmptyState: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    color: var(--black-dark);
    text-align: center;
  `,
  EmptyStateTitle: styled.h2`
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  `,
  EmptyStateText: styled.div`
    font-size: 20px;
    line-height: 26px;
  `,
};

export const User = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Name: styled.span`
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  Email: styled.span`
    font-size: 14px;
    line-height: 16px;
    color: var(--bright-gray);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
};
