import styled from 'styled-components';

export const Container = styled.div<{ direction?: 'column' | 'row' }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  flex-wrap: wrap;
  gap: ${({ direction }) => (direction && direction === 'row' ? '5px' : '1px')};
  align-items: ${({ direction }) => (direction && direction === 'row' ? 'center' : 'flex-start')};
`;

export const TeamWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;
