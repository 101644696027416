import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ICertificationsDataCtx, IFilterItem } from 'contexts/certifications/types';
import Notification from 'components/notification';
import { getInvitations } from 'services/users';
import {
  getCertificationsCountersAction,
  getEmptyStateAction,
  getUsersCertificationsLogsAction,
} from 'contexts/certifications/actions';
import { cloneDeep } from 'lodash';
import { certificationsInitialValues } from 'contexts/certifications/utils';
import { SessionContext } from 'contexts/session';
import { isEmpty } from 'lodash';
interface CertificationsContentHook {
  fetching: boolean;
  fetchingCounters: boolean;
  fetchingUsers: boolean;
  fetchingTable: boolean;
  fetchingEmptyState: boolean;
  filters: ICertificationsDataCtx['filters'];
  counters: ICertificationsDataCtx['counters'];
  users: any;
  usersTable: any;
  usersCount: number;
  emptyState: boolean;
  changeFilters: (newValue: IFilterItem) => void;
}

const useCertificationsContent = (): CertificationsContentHook => {
  const [filters, setFilters] = useState(certificationsInitialValues.filters);
  const [counters, setCounters] = useState(certificationsInitialValues.counters);
  const [fetching, setFetching] = useState<boolean>(false);
  const [fetchingCounters, setFetchingCounters] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [fetchingTable, setFetchingTable] = useState(false);
  const [fetchingEmptyState, setFetchingEmptyState] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [usersTable, setUsersTable] = useState<any[]>([]);
  const [usersCount, setUsersCount] = useState(0);
  const [emptyState, setEmptyState] = useState(false);
  const { user, account, isTeamManager } = useContext(SessionContext);

  const getUsers = async (query: string): Promise<any[]> => {
    setFetchingUsers(true);
    const teams = !isTeamManager
      ? filters?.teams.map((team) => team.slug).join(',')
      : currentTeamSlugs;
    try {
      return getInvitations({
        active: 'true',
        license: null,
        teams,
        roles: null,
        page: 1,
        page_size: 500,
        search: query || '',
      }).then((response) => {
        if (response.status === 200) {
          setFetchingUsers(false);
          const newUsers = response.data.results.map((u) => {
            return {
              email: u.email,
              id: u.uaa_id,
              name: `${u.uaa_data?.profile?.data?.first_name} ${u.uaa_data?.profile?.data?.last_name}`,
            };
          });
          setUsers(newUsers);
          return newUsers;
        }
        return users;
      });
    } catch (error) {
      setFetchingUsers(false);
      Notification({ text: 'There has been a problem fetching users', type: 'error' });
    }
  };

  const getUsersCertificationsLogs = async (resetUsers = false) => {
    try {
      setFetchingTable(true);
      const {
        certification_short_names,
        result,
        user_ids,
        expiration_date_range,
        page,
        page_size,
        teams,
      } = filters;
      const response = await getUsersCertificationsLogsAction(
        {
          certification_short_names,
          result,
          user_ids: resetUsers ? [] : user_ids,
          teams,
          expiration_date_range,
          page,
          page_size,
        },
        account?.id,
        currentTeamIds
      );

      if (response instanceof Error) {
        throw response;
      }

      setUsersTable(response.results);
      setUsersCount(response.count);
    } catch (error) {
      Notification({
        text: 'There has been a problem fetching users certifications logs',
        type: 'error',
      });
    } finally {
      setFetchingTable(false);
    }
  };

  const getEmptyState = async () => {
    try {
      setFetchingEmptyState(true);
      const response = await getEmptyStateAction(account?.id);

      if (response instanceof Error) {
        throw response;
      }

      setEmptyState(response);
      return response;
    } catch (error) {
      Notification({
        text: 'There has been a problem fetching data',
        type: 'error',
      });
    } finally {
      setFetchingEmptyState(false);
    }
  };

  const fetchAllData = async (isFirstRender = false) => {
    setFetching(true);
    const teamUsers = await getUsers('');
    let counterAndExpirationData = [];
    if (!teamUsers.length) {
      setCounters(certificationsInitialValues.counters);
    } else {
      counterAndExpirationData = [getCounters()];
    }
    const resetUsers = true;
    await Promise.allSettled([getUsersCertificationsLogs(resetUsers), ...counterAndExpirationData]);
    setFetching(false);
  };

  const { teams, ...otherFilters } = filters;

  const memorizedTeams = useMemo(() => teams, [JSON.stringify(teams)]);
  const memorizedOtherFilters = useMemo(() => otherFilters, [JSON.stringify(otherFilters)]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    setFilters({ ...filters, user_ids: [] });
    if (!isFirstRender.current) fetchAllData();
  }, [memorizedTeams]);

  useEffect(() => {
    if (isFirstRender.current) {
      const firstRender = true;
      fetchAllData(firstRender);
      isFirstRender.current = false;
    } else {
      getUsersCertificationsLogs();
    }
  }, [memorizedOtherFilters]);

  const getCounters = async () => {
    setFetchingCounters(true);
    try {
      const payload = {
        account_id: account?.id,
        team_ids: currentTeamIds,
      };
      const response = await getCertificationsCountersAction(payload);
      if (response instanceof Error) {
        throw response;
      }
      setCounters(response.results);
    } catch (error) {
      setFetchingCounters(false);
      Notification({ text: 'There has been a problem fetching counters', type: 'error' });
    } finally {
      setFetchingCounters(false);
    }
  };

  const changeFilters = (newValue: IFilterItem) => {
    if (isEmpty(newValue.teams)) {
      const newValueClone = cloneDeep(newValue);
      const teams = [];
      user.teams.forEach((team) => team.is_manager && teams.push(team));
      newValueClone.teams = teams;
      setFilters(newValueClone);
    } else {
      setFilters(newValue);
    }
  };

  const teamManagerTeamIds = useMemo(() => {
    if (!isTeamManager) return [];
    return user.teams.filter((team) => team?.is_manager).map((team) => team.team_id);
  }, [user]);

  const currentTeamIds = useMemo(() => {
    if (filters.teams.length) {
      return filters.teams.map((team) => team.id || team.team_id);
    } else {
      return teamManagerTeamIds;
    }
  }, [memorizedTeams]);

  const currentTeamSlugs = useMemo(() => {
    if (!filters.teams.length) return '';
    return filters.teams
      .map((team) => team.slug)
      .filter(Boolean)
      .join(',');
  }, [memorizedTeams]);

  return {
    fetching: fetching,
    fetchingCounters,
    fetchingUsers,
    fetchingTable,
    fetchingEmptyState,
    filters,
    users,
    usersTable,
    usersCount,
    counters,
    emptyState,
    changeFilters,
  };
};

export default useCertificationsContent;
