import CertificationsCounters from './components/CertificationsCounters/CertificationsCounters';
import TeamsFilter from 'components/TeamsFilter/TeamsFilter';
import CertificationTable from './components/CertificationsTable/CertificationsTable';
import CertificationsProvider, { CertificationContext } from 'contexts/certifications';
import NotificationBanner from 'components/NotificationBanner/NotificationBanner';
import Button from 'components/button';
import { Container, Counters, CtaWrapper, Header, Title } from './components/styles';
import { useContext } from 'react';
import { SessionContext } from 'contexts/session';
import { isMobile } from 'react-device-detect';
import { CERTIFICATIONS_FAQ_URL } from 'constants/certifications';
const Certifications = (): JSX.Element => {
  return (
    <CertificationsProvider>
      <Container isMobile={isMobile}>
        <CertificationsContent />
      </Container>
    </CertificationsProvider>
  );
};

const CertificationsContent = (): JSX.Element => {
  const { actions, emptyState, fetchingEmptyState, filters } = useContext(CertificationContext);
  const { isImpersonated } = useContext(SessionContext);

  return fetchingEmptyState ? (
    <>Spinner</>
  ) : emptyState ? (
    <>Empower your team with certifications!</>
  ) : (
    <>
      <Header>
        <Title>Certifications</Title>
        <CtaWrapper>
          <TeamsFilter actions={actions} filters={filters} noMobile={true} />
          <Button
            $secondary
            onClick={() => {
              window.open(CERTIFICATIONS_FAQ_URL, '_blank');
            }}
          >
            FAQs
          </Button>
        </CtaWrapper>
      </Header>
      <NotificationBanner
        styles={{ marginTop: '10px', padding: '15px' }}
        impersonated={isImpersonated}
        onlyMobile={true}
        noCloseButton={true}
        infoIcon={true}
        text={`To access the full certifications view, including the certifications history, please switch to a larger screen.`}
      />
      <Counters>
        <CertificationsCounters />
      </Counters>
      <CertificationTable noMobile={true} />
    </>
  );
};

export default Certifications;
