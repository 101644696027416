import { useContext, useEffect, useState } from 'react';
import { AnalyticsDataCtx, IFilterItem } from 'contexts/analytics/types';
import { analyticsInitialValues } from 'contexts/analytics/utils';
import {
  getActiveUsersAction,
  getAgregationAction,
  getCoursesTableAction,
  getLabsAction,
  getSeriesByTypeAction,
  getTeamsTableAction,
  getTeamsTableActivitiesAction,
  getTeamsTableLabsAction,
  getTeamsTableQuizzAction,
  getUsersTableAction,
  getUsersTableActivitiesAction,
  getUsersTableLabsAction,
  getUsersTableQuizzAction,
  getUsersWithActivityTotalAction,
  getUsersWithActivityUsersTableAction,
} from 'contexts/analytics/actions';
import { getUsersCounts } from 'contexts/users/actions';
import cloneDeep from 'lodash.clonedeep';

import { UsersCounts } from 'contexts/users/types';

import {
  ACTIVITIES_TYPE,
  COURSES_ENTITY,
  ENTITY_COLUMN_ACTIONS,
  FINISHED_STATUS,
  LABS_PERCENTAGE,
  LABS_TYPE,
  OVERALL_STATUS,
  PASSED_STATUS,
  QUERIES_BY_TYPE,
  QUIZZES_PERCENTAGE,
  QUIZZES_TYPE,
  STARTED_STATUS,
  STATISTICS_ENTITIES,
  TEAMS_ENTITY,
  USERS_ENTITY,
  USERS_TYPE,
  VIDEOS_OVERALL_TYPE,
  VIDEOS_PERCENTAGE,
  VIDEOS_TYPE,
  WATCHED_STATUS,
} from 'constants/analytics';
import { SessionContext } from 'contexts/session';

interface AnalyticsContentHook {
  fetching: boolean;
  fetchingCounters: boolean;
  fetchingTabCounters: boolean;
  isEmpty: boolean;
  filters: AnalyticsDataCtx['filters'];
  tableOrdering: AnalyticsDataCtx['tableOrdering'];
  activitiesChart: AnalyticsDataCtx['activities'];
  activeUsersChart: AnalyticsDataCtx['activeUsers'];
  entitiesTable: AnalyticsDataCtx['entitiesTable'];
  counters: AnalyticsDataCtx['counters'];
  error: string | boolean;
  currentChart: string;
  updateChart: (currentChart: string) => void;
  updateEntity: (currentEntity: string) => void;
  changeFilters: (newValue: IFilterItem) => void;
  changePagination: (value: { name: string; value: string }) => void;
  changeOrdering: (newValue: string) => void;
}

const useAnalyticsContent = (): AnalyticsContentHook => {
  const [filters, setFilters] = useState(analyticsInitialValues.filters);
  const [tableOrdering, setOrdering] = useState(analyticsInitialValues.tableOrdering);
  const [activitiesChart, setActivitiesChart] = useState(analyticsInitialValues.activities);
  const [activeUsersChart, setActiveUsersChart] = useState(analyticsInitialValues.activeUsers);
  const [entitiesTable, setEntitiesTable] = useState(analyticsInitialValues.entitiesTable);
  const [counters, setCounters] = useState(analyticsInitialValues.counters);
  const [fetching, setFetching] = useState<boolean>(false);
  const [fetchingCounters, setFetchingCounters] = useState<boolean>(false);
  const [fetchingTabCounters, setFetchingTabCounters] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [currentChart, setCurrentChart] = useState(analyticsInitialValues.currentChart);

  const { user, account, teams, isAccountOwner } = useContext(SessionContext);
  const business_account_id = account?.id;
  const uaa_id = user.uaa_id;

  let totalAndLicensedUser;

  const getActivitiesChartData = async ({ params }, type?: string) => {
    try {
      const property = type ? type : 'items';
      setActivitiesChart((prevState) => ({ ...prevState, loading: true }));
      const chartData = await getSeriesByTypeAction(params);
      if (chartData instanceof Error || !chartData?.results?.length) {
        setActivitiesChart(() => ({
          ...analyticsInitialValues.activities,
          error: chartData instanceof Error ? chartData?.message : 'no results',
        }));
      } else {
        setActivitiesChart((prevState) => ({
          ...prevState,
          count: chartData?.results?.length,
          [property]: chartData?.results,
        }));
        setIsEmpty(chartData.results);
      }
      setActivitiesChart((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      // handle error
    }
  };

  const getCountersData = async ({ params }, chartType, status) => {
    try {
      const countersData = await getAgregationAction(params);
      if (countersData instanceof Error) {
        setCounters((prevState) => ({
          ...prevState,
          [chartType.toLowerCase()]: {
            ...prevState[chartType.toLowerCase()],
            error: countersData.message,
          },
        }));
      } else {
        setCounters((prevState) => ({
          ...prevState,
          [chartType.toLowerCase()]: {
            ...prevState[chartType.toLowerCase()],
            [status.toLowerCase()]: countersData.results,
          },
        }));
        if (status === WATCHED_STATUS.VALUE) {
          // WATCHED_STATUS.VALUE is used for VIDEOS_TYPE and for VIDEOS_OVERALL_TYPE.VALUE
          setCounters((prevState) => ({
            ...prevState,
            [VIDEOS_OVERALL_TYPE.VALUE.toLowerCase()]: {
              ...prevState[VIDEOS_OVERALL_TYPE.VALUE.toLowerCase()],
              [status.toLowerCase()]: countersData.results,
            },
          }));
        }
        if (status === STARTED_STATUS.VALUE) {
          setCounters((prevState) => {
            const updatedActivities = { ...prevState.activities };
            switch (chartType) {
              case QUIZZES_TYPE.VALUE:
                updatedActivities.quizzes = countersData.results;
                break;
              case LABS_TYPE.VALUE:
                updatedActivities.labs = countersData.results;
                break;
              case VIDEOS_TYPE.VALUE:
                updatedActivities.videos = countersData.results;
            }
            return {
              ...prevState,
              activities: updatedActivities,
            };
          });
        }
      }
    } catch (error) {
      // handle error
    }
  };

  const getCountersForTabs = async () => {
    setFetchingTabCounters(true);
    try {
      //For any chart type, we need to get the counters for the tabs numbers
      //Tab users with activity
      getActiveUsersCounters();
      //Tab activities  -  Total Activities value
      let chartCounter = QUERIES_BY_TYPE[ACTIVITIES_TYPE.VALUE.toLocaleLowerCase()];
      await getCountersData(
        {
          params: handleReqData({
            dataType: 'counters',
            dataQueries: chartCounter,
            status: OVERALL_STATUS.VALUE,
          }),
        },
        chartCounter.TYPE,
        OVERALL_STATUS.VALUE
      );
      //Tab videos -   Time wathched value
      chartCounter = QUERIES_BY_TYPE[VIDEOS_TYPE.VALUE.toLocaleLowerCase()];
      await getCountersData(
        {
          params: handleReqData({
            dataType: 'counters',
            dataQueries: chartCounter,
            status: WATCHED_STATUS.VALUE,
          }),
        },
        chartCounter.TYPE,
        WATCHED_STATUS.VALUE
      );
      // Tab quizzes - Passed value
      chartCounter = QUERIES_BY_TYPE[QUIZZES_TYPE.VALUE.toLocaleLowerCase()];
      await getCountersData(
        {
          params: handleReqData({
            dataType: 'counters',
            dataQueries: chartCounter,
            status: PASSED_STATUS.VALUE,
          }),
        },
        chartCounter.TYPE,
        PASSED_STATUS.VALUE
      );
      // Tab labs - Started value
      chartCounter = QUERIES_BY_TYPE[LABS_TYPE.VALUE.toLocaleLowerCase()];
      await getCountersData(
        {
          params: handleReqData({
            dataType: 'counters',
            dataQueries: chartCounter,
            status: STARTED_STATUS.VALUE,
          }),
        },
        chartCounter.TYPE,
        STARTED_STATUS.VALUE
      );
    } finally {
      setFetchingTabCounters(false);
    }
  };

  const getExtraCountersForCurrentChart = async () => {
    setFetchingCounters(true);
    try {
      switch (currentChart) {
        case USERS_TYPE.VALUE.toLocaleLowerCase():
          // already in getCountersForTabs
          break;
        case ACTIVITIES_TYPE.VALUE.toLocaleLowerCase():
          //Tab  Overal Activities - Total activities and initiated Labs counters are included for tabs values
          // Need to get Total Video started  and Started quizzes for this tab
          await getCountersData(
            {
              params: handleReqData({
                dataType: 'counters',
                dataQueries: QUERIES_BY_TYPE[QUIZZES_TYPE.VALUE.toLocaleLowerCase()],
                status: STARTED_STATUS.VALUE,
              }),
            },
            QUERIES_BY_TYPE[QUIZZES_TYPE.VALUE.toLocaleLowerCase()].TYPE,
            STARTED_STATUS.VALUE
          );
          await getCountersData(
            {
              params: handleReqData({
                dataType: 'counters',
                dataQueries: QUERIES_BY_TYPE[VIDEOS_OVERALL_TYPE.VALUE.toLocaleLowerCase()],
                status: STARTED_STATUS.VALUE,
              }),
            },
            QUERIES_BY_TYPE[VIDEOS_TYPE.VALUE.toLocaleLowerCase()].TYPE,
            STARTED_STATUS.VALUE
          );
          break;
        case VIDEOS_TYPE.VALUE.toLocaleLowerCase():
          //Tab Videos toggle OVERALL
          await getCountersData(
            {
              params: handleReqData({
                dataType: 'counters',
                dataQueries: QUERIES_BY_TYPE[currentChart],
                status: STARTED_STATUS.VALUE,
              }),
            },
            VIDEOS_TYPE.VALUE,
            STARTED_STATUS.VALUE
          );
          await getCountersData(
            {
              params: handleReqData({
                dataType: 'counters',
                dataQueries: QUERIES_BY_TYPE[currentChart],
                status: FINISHED_STATUS.VALUE,
              }),
            },
            VIDEOS_TYPE.VALUE,
            FINISHED_STATUS.VALUE
          );
          break;
        case VIDEOS_OVERALL_TYPE.VALUE.toLocaleLowerCase():
          //Tab Videos toggle TIME WATCHED - Total time watched included for tabs values
          break;
        case QUIZZES_TYPE.VALUE.toLocaleLowerCase():
          //Tab Quizzes - Passed value included for tabs values
          await getCountersData(
            {
              params: handleReqData({
                dataType: 'counters',
                dataQueries: QUERIES_BY_TYPE[currentChart],
                status: STARTED_STATUS.VALUE,
              }),
            },
            QUIZZES_TYPE.VALUE,
            STARTED_STATUS.VALUE
          );
          await getCountersData(
            {
              params: handleReqData({
                dataType: 'counters',
                dataQueries: QUERIES_BY_TYPE[currentChart],
                status: FINISHED_STATUS.VALUE,
              }),
            },
            QUIZZES_TYPE.VALUE,
            FINISHED_STATUS.VALUE
          );
          break;
        case LABS_TYPE.VALUE.toLocaleLowerCase():
          //Tab Labs - Started value included for tabs values
          await getCountersData(
            {
              params: handleReqData({
                dataType: 'counters',
                dataQueries: QUERIES_BY_TYPE[currentChart],
                status: STARTED_STATUS.VALUE,
              }),
            },
            LABS_TYPE.VALUE,
            STARTED_STATUS.VALUE
          );
          await getCountersData(
            {
              params: handleReqData({
                dataType: 'counters',
                dataQueries: QUERIES_BY_TYPE[currentChart],
                status: FINISHED_STATUS.VALUE,
              }),
            },
            LABS_TYPE.VALUE,
            FINISHED_STATUS.VALUE
          );
          break;
      }
    } finally {
      setFetchingCounters(false);
    }
  };

  const getAllCountersData = async () => {
    await getCountersForTabs();
    await getExtraCountersForCurrentChart();
  };

  const fetchData = async () => {
    try {
      const data = (await getUsersCounts({
        active: true,
        account_id: business_account_id,
      })) as UsersCounts;
      totalAndLicensedUser = data as UsersCounts;
    } catch (error) {
      // handle error
    }
  };

  const getActiveUsersCounters = async () => {
    const teamsIds = [];
    if (!isAccountOwner) {
      user.teams.forEach((team) => team.is_manager && teamsIds.push(team.team_id));
    }

    const activeUsersTotalWithLicense = await getUsersWithActivityTotalAction({
      user_id: uaa_id,
      account_id: business_account_id,
      date_from: filters.date_from,
      date_to: filters.date_to,
      filter_licensed_users: true,
      team_ids: teamsIds.length ? teamsIds.join(',') : '',
    });

    const totalActivesWithLicense =
      activeUsersTotalWithLicense instanceof Error ? 0 : activeUsersTotalWithLicense.results.result;

    const userType = USERS_TYPE.VALUE.toLowerCase();

    if (!totalAndLicensedUser) {
      await fetchData();
    }
    if (!(totalAndLicensedUser instanceof Error)) {
      setCounters((prevState) => ({
        ...prevState,
        [userType]: {
          ...prevState[userType],
          total: totalAndLicensedUser.active_count.active,
          licensed: totalAndLicensedUser.license_count.license,
          withActivity: totalActivesWithLicense,
        },
      }));
    }
  };
  const getActiveUsersData = async ({ params }) => {
    try {
      setActivitiesChart((prevState) => ({ ...prevState, loading: true }));
      const chartData = await getActiveUsersAction(params);
      if (chartData instanceof Error || !chartData?.results?.length) {
        setActivitiesChart(() => ({
          ...analyticsInitialValues.activities,
          error: chartData instanceof Error ? chartData?.message : 'no results',
        }));
      } else {
        setActivitiesChart((prevState) => ({
          ...prevState,
          count: chartData?.results?.length,
          items: chartData?.results,
        }));

        setIsEmpty(chartData.results);
      }
      setActivitiesChart((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      // handle error
    }
  };

  const getLabsData = async ({ params }) => {
    interface ILabItem {
      count: number;
      standard: number;
      skill_dive: number;
      date: string;
    }
    interface ILabData {
      results?: {
        items: ILabItem[];
        total: {
          count: number;
          standard: number;
          skill_dive: number;
        };
      };
    }
    try {
      setActivitiesChart((prevState) => ({ ...prevState, loading: true }));
      const labsData: ILabData | Error = await getLabsAction(params);
      if (labsData instanceof Error) {
        setActivitiesChart((prevState) => ({ ...prevState, error: labsData?.message }));
      } else {
        const { results } = labsData;
        const total = results.items.map((item) => {
          return { date: item.date, count: item.count };
        });
        const standard = results.items.map((item) => {
          return { date: item.date, count: item.standard };
        });
        const skill_dive = results.items.map((item) => {
          return { date: item.date, count: item.skill_dive };
        });
        setActivitiesChart((prevState) => ({
          ...prevState,
          count: results?.items?.length,
          items: total,
          total,
          standard,
          skill_dive,
        }));
        setIsEmpty(!!results);
      }

      const labsType = LABS_TYPE.VALUE.toLowerCase();
      if (!(labsData instanceof Error)) {
        const countersData = labsData.results.total;
        setCounters((prevState) => ({
          ...prevState,
          [labsType]: {
            ...prevState[labsType],
            total: countersData.count,
            standard: countersData.standard,
            skill_dive: countersData.skill_dive,
          },
        }));
      }
      setActivitiesChart((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      // handle error
    }
  };

  const getActiveUsersDataForUsersTab = async (params: any) => {
    switch (currentChart) {
      case 'users':
        return await getUsersWithActivityUsersTableAction(params);
      case 'quizzes':
        return await getUsersTableQuizzAction(params);
      case 'activities':
        return await getUsersTableActivitiesAction(params);
      case 'labs':
        return await getUsersTableLabsAction(params);
      default:
        return await getUsersTableAction(params);
    }
  };

  const getActiveTeamsDataForTeamsTab = async (params: any) => {
    switch (currentChart) {
      case 'activities':
        return await getTeamsTableActivitiesAction(params);
      case 'quizzes':
        return await getTeamsTableQuizzAction(params);
      case 'labs':
        return await getTeamsTableLabsAction(params);
      default:
        return await getTeamsTableAction(params);
    }
  };

  const getEntitiesTableData = async ({ params }, entity?: string) => {
    setEntitiesTable((prevState) => ({ ...prevState, loading: true }));
    let activeUsersData;
    const current = entity ? entity : entitiesTable.currentEntity;
    try {
      if (current === TEAMS_ENTITY.VALUE) {
        activeUsersData = await getActiveTeamsDataForTeamsTab(params);
      }
      if (current === COURSES_ENTITY.VALUE) {
        activeUsersData = await getCoursesTableAction(params);
      }
      if (current === USERS_ENTITY.VALUE) {
        activeUsersData = await getActiveUsersDataForUsersTab(params);
      }
      if (activeUsersData instanceof Error) {
        setEntitiesTable((prevState) => ({
          ...prevState,
          error: activeUsersData?.message,
          loading: false,
        }));
      } else {
        setEntitiesTable((prevState) => ({
          ...prevState,
          loading: false,
          count: activeUsersData?.results?.pagination?.total,
          items: activeUsersData?.results?.data,
          pageSize: activeUsersData?.results?.pagination.page_size,
          page: activeUsersData?.results?.pagination.page,
          currentEntity: current,
          unavailable:
            (current === TEAMS_ENTITY.VALUE &&
              ![
                ACTIVITIES_TYPE.VALUE.toLowerCase(),
                QUIZZES_TYPE.VALUE.toLowerCase(),
                VIDEOS_OVERALL_TYPE.VALUE.toLocaleLowerCase(),
                VIDEOS_TYPE.VALUE.toLocaleLowerCase(),
                LABS_TYPE.VALUE.toLowerCase(),
              ].includes(currentChart)) ||
            current === COURSES_ENTITY.VALUE,
        }));
        setIsEmpty(activeUsersData.results);
      }
      setEntitiesTable((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setEntitiesTable((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const updateChart = (currentChart: string) => {
    setCurrentChart(currentChart);
  };

  const updateEntity = (currentValue?: string) => {
    getEntitiesTableData(
      { params: handleReqData({ dataType: 'table', page: '1', selected_entity: currentValue }) },
      currentValue
    );
  };

  const changeFilters = (newValue: IFilterItem) => {
    if (newValue.team_ids === '') {
      const newValueClone = cloneDeep(newValue);
      const teamsIds = [];
      user.teams.forEach((team) => team.is_manager && teamsIds.push(team.team_id));
      newValueClone.team_ids = teamsIds.join(',');
      setFilters(newValueClone);
    } else {
      setFilters(newValue);
    }
  };

  const changePagination = (value: { name: string; value: string }) => {
    getEntitiesTableData({
      params: handleReqData({ dataType: 'table', [value.name]: value.value }),
    });
  };

  const changeOrdering = (newValue: string) => {
    setOrdering(newValue);
  };

  const getChartData = async () => {
    setActivitiesChart((prevState) => ({ ...prevState, loading: true }));
    if (currentChart === USERS_TYPE.VALUE.toLowerCase()) {
      getActiveUsersData({ params: handleReqData({ dataType: 'activeUsers' }) });
      return;
    }
    if (currentChart === ACTIVITIES_TYPE.VALUE.toLowerCase()) {
      await Promise.all([
        getActivitiesChartData({
          params: handleReqData({
            dataType: 'charts',
            dataQueries: QUERIES_BY_TYPE[currentChart],
            status: OVERALL_STATUS.VALUE,
          }),
        }),
        getActivitiesChartData(
          {
            params: handleReqData({
              dataType: 'charts',
              dataQueries: QUERIES_BY_TYPE[currentChart],
              status: LABS_PERCENTAGE.VALUE,
            }),
          },
          'labs'
        ),
        getActivitiesChartData(
          {
            params: handleReqData({
              dataType: 'charts',
              dataQueries: QUERIES_BY_TYPE[currentChart],
              status: VIDEOS_PERCENTAGE.VALUE,
            }),
          },
          'videos'
        ),
        getActivitiesChartData(
          {
            params: handleReqData({
              dataType: 'charts',
              dataQueries: QUERIES_BY_TYPE[currentChart],
              status: QUIZZES_PERCENTAGE.VALUE,
            }),
          },
          'quizzes'
        ),
      ]);
      return;
    }
    if (currentChart === LABS_TYPE.VALUE.toLowerCase()) {
      getLabsData({ params: handleReqData({ dataType: '' }) });
      return;
    }
    await Promise.all([
      getActivitiesChartData({
        params: handleReqData({
          dataType: 'charts',
          dataQueries: QUERIES_BY_TYPE[currentChart],
          status: OVERALL_STATUS.VALUE,
        }),
      }),
      getActivitiesChartData(
        {
          params: handleReqData({
            dataType: 'charts',
            dataQueries: QUERIES_BY_TYPE[currentChart],
            status: FINISHED_STATUS.VALUE,
          }),
        },
        'finished'
      ),
      getActivitiesChartData(
        {
          params: handleReqData({
            dataType: 'charts',
            dataQueries: QUERIES_BY_TYPE[currentChart],
            status: STARTED_STATUS.VALUE,
          }),
        },
        'started'
      ),
      currentChart === QUIZZES_TYPE.VALUE.toLowerCase() &&
        getActivitiesChartData(
          {
            params: handleReqData({
              dataType: 'charts',
              dataQueries: QUERIES_BY_TYPE[currentChart],
              status: PASSED_STATUS.VALUE,
            }),
          },
          'passed'
        ),
    ]);
  };

  useEffect(() => {
    // Fetch current chart data, tabs counters and current chart counters whenever filters change or teams change
    const fetchData = async () => {
      setFetching(true);
      try {
        await getChartData();
        await getAllCountersData();
      } finally {
        setFetching(false);
      }
    };
    fetchData();
  }, [filters, teams]);

  useEffect(() => {
    // Fetch current chart data and counters for current chart counters whenever current chart changes
    const fetchData = async () => {
      try {
        setFetchingCounters(true);
        await getChartData();
        await getExtraCountersForCurrentChart();
      } finally {
        setFetchingCounters(false);
      }
    };
    fetchData();
  }, [currentChart]);

  const entityOption = () => {
    if (!STATISTICS_ENTITIES[currentChart.toUpperCase()]) return ''; //default, has all entities
    const currentOption = entitiesTable.currentEntity;
    const hasOption = STATISTICS_ENTITIES[currentChart.toUpperCase()]?.some(
      (entity) => entity.VALUE === currentOption
    );
    return hasOption ? currentOption : 'users';
  };

  useEffect(() => {
    const fetchTable = async () => {
      await getEntitiesTableData(
        { params: handleReqData({ dataType: 'table', page: '1' }) },
        entityOption()
      );
    };
    fetchTable();
  }, [filters, tableOrdering, currentChart]);

  const handleReqData = (dataQuery: {
    dataType: any;
    status?: any;
    dataQueries?: any;
    page?: string;
    page_size?: string;
    selected_entity?: string;
  }) => {
    let params = {
      date_to: filters.date_to,
      date_from: filters.date_from,
      period: filters.period,
      team_ids: filters.team_ids,
      user_id: uaa_id,
      account_id: business_account_id,
      ordering: tableOrdering,
    };

    if (!params.team_ids) {
      const teamsIds = [];
      if (!isAccountOwner) {
        user.teams.forEach((team) => team.is_manager && teamsIds.push(team.team_id));
      }
      params.team_ids = teamsIds.join(',');
    }

    if (dataQuery.dataType === 'activeUsers') {
      params['filter_licensed_users'] = true;
    }

    if (dataQuery.dataType === 'table') {
      const newparams = ENTITY_COLUMN_ACTIONS[entitiesTable.currentEntity].reduce((acc, column) => {
        acc[column.key] = QUERIES_BY_TYPE[currentChart][column.actions]?.ACTIONS;
        if (currentChart === 'videos' || currentChart === 'videos_overall') {
          acc['column_external_action'] =
            dataQuery.selected_entity === 'courses'
              ? 'videos_watched_time_courses'
              : 'videos_watched_time_users';
        }
        if (currentChart === 'quizzes') {
          acc['column_metadata'] = 'passed:true';
        }
        return acc;
      }, {});
      params = { ...params, ...newparams };
      params['page'] = dataQuery.page ? dataQuery.page : entitiesTable.page;
      params['page_size'] = dataQuery.page_size ? dataQuery.page_size : entitiesTable.pageSize;
    }
    if (dataQuery.dataType === 'charts') {
      params['actions'] = dataQuery.dataQueries[dataQuery.status].ACTIONS;
      params['external_action'] = dataQuery.dataQueries[dataQuery.status].EXTERNAL_ACTION;
      params['metadata'] = dataQuery.dataQueries[dataQuery.status].METADATA;
    }
    if (dataQuery.dataType === 'counters') {
      params['actions'] = dataQuery.dataQueries[dataQuery.status].ACTIONS;
      params['external_action'] = dataQuery.dataQueries[dataQuery.status].EXTERNAL_ACTION;
      params['metadata'] = dataQuery.dataQueries[dataQuery.status].METADATA;
    }

    return params;
  };

  return {
    fetching: fetching,
    fetchingCounters,
    fetchingTabCounters,
    isEmpty,
    filters,
    tableOrdering,
    error: false,
    activitiesChart,
    activeUsersChart,
    entitiesTable,
    counters,
    currentChart,
    updateChart,
    updateEntity,
    changeFilters,
    changePagination,
    changeOrdering,
  };
};

export default useAnalyticsContent;
