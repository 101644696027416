import React from 'react';
import { Container, TeamWrapper } from './styles';
import TooltipV2 from 'components/TooltipV2';

interface DisplayTeamsProps {
  teams: string[];
  maxTags?: number;
  direction?: 'column' | 'row';
}

const DisplayTeams: React.FC<DisplayTeamsProps> = ({ teams, maxTags, direction }) => {
  const tags = maxTags ?? 1;
  if (teams.length <= tags) {
    return (
      <Container>
        {teams.map((team, index) => (
          <TeamWrapper key={index}>{team}</TeamWrapper>
        ))}
      </Container>
    );
  }

  const firstTeam = teams.slice(0, 1);
  const remainingTeamsCount = teams.length - 1;
  const remainingTeams = teams.slice(1);

  return (
    <Container direction={direction}>
      <TeamWrapper>{firstTeam}</TeamWrapper>
      <TooltipV2
        placement="top"
        content={
          <div>
            {remainingTeams.map((team, index) => (
              <div key={index} style={{ marginBottom: '5px' }}>
                {team}
              </div>
            ))}
          </div>
        }
      >
        <span className="pill pill--plus">{`+${remainingTeamsCount}`}</span>
      </TooltipV2>
    </Container>
  );
};

export default DisplayTeams;
