import { screenMd, screenSm, screenXlg, screenXs } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const Container = styled.div<{ noMobile?: boolean }>`
  display: ${({ noMobile }) => (noMobile ? 'none' : 'flex')};
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 20px 30px;
  background-color: #fff;
  border: 1px solid #e9e9ed;
  border-radius: 15px;
  margin-bottom: 65px;
  ${screenSm} {
    display: ${(noMobile) => noMobile && 'flex'};
    min-height: 500px;
  }
  ${screenMd} {
    margin-bottom: 0;
  }
`;

export const CertificationsContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  margin-top: 15px;
  flex-direction: column;
  ${screenXlg} {
    flex-direction: row;
  } ;
`;

export const ClearInput = styled.div`
  position: absolute;
  div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin: 10px 0px 0 190px;
    border-radius: 50%;
    transition: background-color 0.3s;
    ${screenXs} {
      margin: 10px 0px 0 210px;
    }
    ${screenSm} {
      margin: 10px 0px 0 57vw;
    }
    ${screenMd} {
      margin: 10px 0px 0 190px;
    }
    &:hover {
      background-color: #fff;
    }
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

export const User = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Name: styled.span`
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  Email: styled.span`
    font-size: 14px;
    line-height: 16px;
    color: var(--bright-gray);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
};

export const ColumnNameIcon = styled.div`
  margin-right: 2px;
`;

export const PaginationContainer = styled.div`
  width: calc(100% + 13px);
  margin-left: -3px;
`;

export const TeamsContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
