import { Tag } from 'antd';
import { screenMd } from 'constants/styles/breakpoints';
import { colors } from 'constants/styles/colors';
import styled from 'styled-components/macro';

export const BodyTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  margin-bottom: 26px;
  text-align: left;
`;

export const BodySubtitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  margin-bottom: 10px;
  text-align: left;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
`;

export const InputTypeSelect = styled.div<{ bgColor?: string; wideInput?: boolean }>`
  background: ${({ bgColor }) => bgColor || 'rgb(242, 242, 247)'};
  min-width: ${({ wideInput }) => (wideInput ? '280px' : '100%')};
  border-radius: 10px;
  border-color: #f2f2f7;
  flex-grow: 0;
  min-height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5px 10px;
  font-size: 12px;
  cursor: pointer;
  flex-wrap: wrap;
  gap: 3px;

  > p {
    margin-right: 10px;
    margin-bottom: 0;
  }
  > img {
    width: 37px;
    height: 37px;
    margin-right: 10px;
  }
`;

export const TeamPill = styled(Tag)<{ bgcolor?: string }>`
  background-color: ${({ bgcolor }) =>
    bgcolor ? colors.dataVisualization[bgcolor].lighter : 'white'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 20px;
  border-width: 0;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 10px;
`;

export const TeamPillContainer = styled.div`
  display: flex;
  gap: 3px;
  width: 100%;
  max-width: calc(100% - 28px);
  position: relative;
  flex-wrap: wrap;
`;
export const TeamPillLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  svg {
    position: absolute;
    right: 10px;
    flex: 0 0 12px;
    margin-left: unset;
  }
`;
