import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  ClearInput,
  FilterContainer,
  FilterTitle,
  FiltersContainer,
  FiltersWrapper,
  SelectContainer,
} from './styles';
import InputSelectMultiple from 'components/inputs/inputSelectMultiple';
import InputSelect from 'components/inputs/inputSelect';
import Icon from 'components/icons';
import Button from 'components/button/Button';
import RangeDatePicker from 'components/rangeDatePicker';
import { addDays, format } from 'date-fns';
import { formatDateInUtc } from 'utils/helpers';
import { certificationStatus, certifications } from 'constants/certifications';
import { CertificationContext } from 'contexts/certifications';

const CertificationsFilters: React.FC = () => {
  const dateFilterOptions = [
    { value: 'default', label: 'Select a date range' },
    { value: 30, label: 'next 30 days' },
    { value: 90, label: 'next 90 days' },
    { value: 365, label: 'next year' },
    { value: 'custom', label: 'custom' },
  ];
  const { users, filters, actions } = useContext(CertificationContext);
  const [dateFilterValue, setDateFilterValue] = useState(dateFilterOptions[0]);
  const [clearDateRange, setClearDateRange] = useState(false);
  const dateFormat = 'yyyy-MM-dd';
  const dateLabelFormat = 'dd LLL';
  const [dateFilter, setDateFilter] = useState({
    from: '',
    to: '',
  });
  const [dateRangeSelected, setDateRangeSelected] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState(null);

  const certificationsOptions = useMemo(
    () =>
      Object.entries(certifications).map(([key, value]) => ({
        label: value.label,
        value: value.short_name,
        key: key,
      })),
    [certifications]
  );

  const certStatus = useMemo(
    () =>
      Object.entries(certificationStatus).map(([key, value]) => ({
        label: value.label,
        value: value.label,
        key: key,
      })),
    [certificationStatus]
  );

  const handleFilterChange = (filter: string, value: any) => {
    const newFilterValue = { [filter]: value, page: 1 };
    actions.onChangeFilters({ ...filters, ...newFilterValue });
  };

  const onDateFilterChange = (value: [string, string], date?: any) => {
    setDateFilter({
      from: value[0],
      to: value[1],
    });
    setDateFilterValue({
      value: 'custom',
      label: `${formatDateInUtc(new Date(value[0]), dateLabelFormat)} - ${formatDateInUtc(
        new Date(value[1]),
        dateLabelFormat
      )}`,
    });
    setClearDateRange(true);
    setDateRangeValue(date);
  };

  const onDateSelectChange = (selectValue: string | number) => {
    setDateRangeSelected(false);
    setClearDateRange(false);
    setDateRangeValue(null);
    setDateFilterValue(dateFilterOptions.find((opt) => opt.value === selectValue));
    if (selectValue !== 'custom') {
      const from = selectValue !== 'default' ? format(new Date(), dateFormat) : '';
      const to =
        selectValue !== 'default'
          ? format(addDays(new Date(), Number(selectValue)), dateFormat)
          : '';
      setDateFilter({
        from,
        to,
      });
    } else {
      setDateRangeSelected(true);
    }
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const expired_range =
      dateFilter?.from && dateFilter?.to ? [dateFilter.from, dateFilter.to] : [];
    handleFilterChange('expiration_date_range', expired_range);
  }, [dateFilter]);

  const ClearInputComponent = () => {
    const onClearInputClick = () => {
      setDateFilterValue(dateFilterOptions[3]);
      setDateRangeValue(null);
      setClearDateRange(false);
    };

    return <ClearInput onClick={() => onClearInputClick()}></ClearInput>;
  };

  const currentUsers = useMemo(() => {
    return (
      users?.map((user) => ({
        label: user.name,
        value: user.id,
        key: user.id,
      })) || []
    );
  }, [users]);

  return (
    <FiltersContainer>
      <FiltersWrapper>
        <FilterContainer>
          <FilterTitle>Filter by Certification</FilterTitle>
          <InputSelectMultiple
            className="multiple-select__filter-content"
            customClassName="certification-filter"
            showArrow={true}
            suffixIcon={<Icon icon={'ChevronDown'} size={'smaller'} />}
            values={filters.certification_short_names}
            placeholder="Select or search a certification"
            options={certificationsOptions}
            reseteable={true}
            onChange={(v) => handleFilterChange('certification_short_names', v)}
          />
        </FilterContainer>

        <FilterContainer>
          <FilterTitle>Filter by result</FilterTitle>
          <InputSelectMultiple
            className="multiple-select__filter-content"
            customClassName="certification-filter"
            showArrow={true}
            suffixIcon={<Icon icon={'ChevronDown'} size={'smaller'} />}
            values={filters.result}
            placeholder="Select a result"
            options={certStatus}
            reseteable={true}
            onChange={(v) => handleFilterChange('result', v)}
          />
        </FilterContainer>

        <FilterContainer>
          <FilterTitle>Filter by User</FilterTitle>
          <InputSelectMultiple
            className="multiple-select__filter-content"
            customClassName="certification-filter"
            showArrow={true}
            suffixIcon={<Icon icon={'ChevronDown'} size={'smaller'} />}
            values={filters.user_ids}
            placeholder="Select a user"
            options={currentUsers}
            reseteable={true}
            optionFilterProp="label"
            onChange={(v) => handleFilterChange('user_ids', v)}
          />
        </FilterContainer>

        <FilterContainer>
          <FilterTitle>Filter by expiration date</FilterTitle>
          <SelectContainer showCustom={dateFilterValue.value === 'custom'}>
            <InputSelect
              value={dateFilterValue.label}
              placeHolder={'Select a date range'}
              options={dateFilterOptions}
              onChange={(selectValue) => onDateSelectChange(selectValue)}
            />
            {clearDateRange && <ClearInputComponent />}
            {dateRangeSelected && (
              <Button $secondary $full size={'small'}>
                <RangeDatePicker
                  value={dateRangeValue}
                  suffixIcon={<Icon icon="Calendar" size="medium" />}
                  onChange={(date, dateString) => onDateFilterChange(dateString, date)}
                />
              </Button>
            )}
          </SelectContainer>
        </FilterContainer>
      </FiltersWrapper>
    </FiltersContainer>
  );
};

export default CertificationsFilters;
