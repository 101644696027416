import { useContext, useEffect, useState } from 'react';
import { SessionContext } from 'contexts/session';
import { getTeamsAction } from 'contexts/teams/actions';
import { Container, TeamPill, TeamsSelectContainer, TopContainer } from './styles';
import TeamSelector from 'components/TeamSelector';

type Props = {
  actions: {
    onChangeFilters: (filters: any) => void;
  };
  filters: any;
  noMobile?: boolean;
};

const TeamsFilter = ({ actions, filters, noMobile = false }: Props): JSX.Element => {
  const { user, isAccountOwner } = useContext(SessionContext);
  const [TMWithOneTeam, setTMWithOneTeam] = useState(false);
  const [accountTeams, setAccountTeams] = useState([]);
  const [teamsSelected, setTeamsSelected] = useState([]);
  const [loading, setIsLoading] = useState(true);

  const teamOjbConstructor = (team) => ({
    team,
    team_id: team.team_id,
    name: team.name,
    slug: team.slug,
    is_manager: !!team.is_manager,
  });

  const getAccountTeamsData = async () => {
    const teamsData = await getTeamsAction({
      account: user?.account,
      paginate: 'false',
      deep_details: 'false',
    });
    if (!(teamsData instanceof Error)) {
      setAccountTeams(teamsData?.results);
      setIsLoading(false);
    }
  };

  const handleTeamsSelectionChange = (selectedTeams) => {
    const teams = selectedTeams?.length ? selectedTeams : [];
    filters.teams = teams;
    const currentTeams = teams.map((team) => ({ ...team, team }));
    setTeamsSelected(currentTeams);
    actions.onChangeFilters({ ...filters });
  };

  useEffect(() => {
    let isMounted = true;
    const updateTeamsSelected = () => {
      if (isMounted) {
        setTeamsSelected(
          TMWithOneTeam ? [teamOjbConstructor(user?.teams.find((team) => team.is_manager))] : []
        );
      }
    };

    updateTeamsSelected();

    return () => {
      isMounted = false;
    };
  }, [TMWithOneTeam, accountTeams, user?.teams]);

  useEffect(() => {
    if (isAccountOwner) {
      getAccountTeamsData();
    } else {
      setTMWithOneTeam(user?.teams?.filter((team) => team.is_manager).length === 1);
      setIsLoading(false);
    }
  }, [isAccountOwner]);

  return (
    <Container className={`teams-filter ${noMobile ? 'no-mobile' : ''}`}>
      <TopContainer>
        <TeamsSelectContainer className="teams-filters__title">
          {TMWithOneTeam ? (
            <>
              <div className="teams-filters__filters">Filter by team:</div>
              <TeamPill
                key={
                  teamsSelected[0]?.team?.team_id
                    ? teamsSelected[0]?.team?.team_id
                    : teamsSelected[0]?.team?.id
                }
                bgcolor={
                  teamsSelected[0]?.team?.icon_color
                    ? teamsSelected[0]?.team?.icon_color
                    : teamsSelected[0]?.team?.icon?.color
                }
              >
                {teamsSelected[0]?.name}
              </TeamPill>
            </>
          ) : (
            <>
              <div className="teams-filters__filters" style={{ minWidth: '87px' }}>
                {!loading && 'Filter by teams'}
              </div>
              <TeamSelector
                analyticsTheme
                teamsSelected={teamsSelected}
                onTeamChange={handleTeamsSelectionChange}
                bgColor={'#fff'}
                wideInput={true}
                wrapperStyles={{ display: 'flex', flex: '1 1 auto', minWidth: '0' }}
                containerStyles={{ display: 'flex', flex: '1 1 auto', minWidth: '0' }}
              />
            </>
          )}
        </TeamsSelectContainer>
      </TopContainer>
    </Container>
  );
};

export default TeamsFilter;
