import { Select } from 'antd';
import { colors } from 'constants/styles/colors';
import styled from 'styled-components';

export const StyledInput = styled.input<{ error?: boolean }>`
  height: 44px;
  font-size: 12px;
  padding: 15px 10px;
  display: block;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  &:focus,
  &:active {
    outline: none;
  }
  &:disabled {
    cursor: disabled;
  }
  ${({ error }) =>
    error &&
    `
   		border: 1px solid red;
  	`}
`;

export const StyledNumericInput = styled(StyledInput)<{ unlimited: boolean }>`
  height: 50px;
  ${({ unlimited }) =>
    unlimited &&
    `
		font-size: 24px;
		max-width: 165px;
	`}
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  > input {
    padding-right: 30px !important;
  }
`;

export const FlatInputContainer = styled.div<{ short?: boolean; twoDigits?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 5px;
  border: 1px solid var(--dusty-gray);
  height: 44px;
  max-width: 117px;
  ${({ short }) =>
    short &&
    `
    justify-content: flex-end;
    width: 87px;
    > input {
      padding-left: 13px !important;
    }
		`}
  > input {
    padding: 8px;
    width: 30px;
    background-color: transparent !important;
    font-weight: 700;
    font-size: 25px;
    color: var(--black-dark);
  }
  ${({ twoDigits }) =>
    twoDigits &&
    `
    > input {
      padding: 0 0 0 4px !important;
    }
		`}
`;

export const FlatButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: transparent;
  border: none;
  width: 24px;
  height: 24px;
  margin: 10px;
`;

export const InputTypeSelect = styled.div<{ error: boolean; disabled?: boolean }>`
  background: rgb(242, 242, 247);
  border-radius: 10px;
  border-color: #f2f2f7;
  flex-grow: 0;
  height: 44px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 3.5px 16px 3.5px 10px;
  font-size: 12px;
  > div,
  img {
    width: 37px;
    height: 37px;
    margin-right: 10px;
  }
  > svg {
    margin-left: auto;
    cursor: pointer;
  }
  ${({ error }) =>
    error &&
    `
		box-shadow: 0px 0px 0px 1px red;
		`}
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    color: ${colors.fonts.lightGray}
		`}
`;

export const SelectDropdown = styled.div<{ full?: boolean; maxHeight?: string }>`
  width: auto;
  ${({ full }) => full && 'width: 100%'};
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 30px 48px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 6px;
  overflow-y: auto;
  max-height: ${({ maxHeight }) => maxHeight || '300px'};
`;

export const InputSelectOptions = styled.div<{ extended?: boolean }>`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  border: none;
  background: #ffffff;
  &:focus,
  &:hover {
    background: ${({ theme }) => theme.default.colors.buttons.blackGray7};
    img {
      background: #ffffff !important;
    }
  }
  &:active {
    background: ${({ theme }) => theme.default.colors.buttons.blackGray6};
  }
  ${({ extended }) =>
    extended &&
    `
    height: 50px;
    display: flex;
    gap: 15px;
    :not(:last-child) {
      border-bottom: 1px solid #f2f2f7;
  }
    `};
`;

export const IconPoint = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--grey-lighter);
`;

export const ExtendedOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExtendedOptionName = styled.div`
  color: var(--black-dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
`;

export const ExtendedOptionInfo = styled.div`
  color: var(--perfect-gray);
  font-size: 10px;
  font-style: normal;
  font-weight: 450;
  line-height: 12px;
`;

export const InputIcon = styled.img`
  width: 25px !important;
  height: 25px !important;
  padding: 5px;
  border-radius: 50px;
  margin-right: 5px !important;
`;

export const InputTypeSelectMultiple = styled(Select).withConfig({
  shouldForwardProp: (prop: string) => prop !== 'clearable',
})<{ clearable?: boolean }>`
  &&& {
    .ant-select-selector {
      padding: 8px;
      border: none;
      border-radius: 10px;
      font-size: 12px;
      width: 100%;
      background: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
    }
    .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.default.colors.fonts.darkGray};
    }
    .ant-select-selection-item {
      background: #ffffff;
      border-radius: 40px;
      padding-left: 10px;
      padding-right: 10px;
      gap: 10px;
    }
    .ant-select-selection-item-content {
      margin-right: 0px;
    }
    .ant-select-selection-item-remove {
      color: ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacLighter};
      line-height: 17px;
      display: ${({ clearable }) => (!clearable ? 'none' : 'inline-block')};
    }
    .ant-checkbox-checked {
      display: none;
    }
    .ant-select-selection-item {
      background: #ffffff;
      border-radius: 40px;
      padding-left: 10px;
      padding-right: 10px;
      gap: 10px;
    }
    .ant-select-selection-item-content {
      margin-right: 0px;
    }
    .ant-select-selection-item-remove {
      color: ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacLighter};
      line-height: 17px;
      display: ${({ clearable }) => (!clearable ? 'none' : 'inline-block')};
    }
    .ant-space {
      gap: 0px !important;
    }
    .anticon-close {
      svg {
        height: 12px;
        width: 12px;
      }
    }
    width: 100%;
  }
`;

export const InputTypeSelectSearch = styled(Select).withConfig({
  shouldForwardProp: (prop: string) => prop !== 'clearable',
})<{ clearable?: boolean }>`
  &&& {
    .ant-select-selector {
      height: 40px;
      padding: 6px 6px 8px 8px;
      border: none;
      border-radius: 10px;
      font-size: 12px;
      width: 100%;
      background: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
    }
    .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.default.colors.fonts.darkGray};
      left: 14px;
    }
    .ant-select-selection-item {
      display: block;
      width: 100%;
    }
    .ant-select-selection-item-content {
      margin-right: 0px;
    }
    .ant-select-selection-item-remove {
      color: ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacLighter};
      line-height: 17px;
      display: ${({ clearable }) => (!clearable ? 'none' : 'inline-block')};
    }
    .ant-checkbox-checked {
      display: none;
    }
    .ant-select-selection-item {
    }
    .ant-space {
      gap: 0px !important;
    }
    .anticon-close {
      svg {
        height: 12px;
        width: 12px;
      }
    }
    width: 100%;
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;
export const AssingedValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 2px 15px 2px 0;
`;

export const AssingedValueName = styled.div`
  color: var(--black-dark);
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
`;

export const InputArrowBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  right: 10px;
  top: 10px;
  color: var(--darker-gray);
  cursor: pointer;
  pointer-events: none;
  transition: transform 0.3s ease;
`;
export const SelectDropdownCheckbox = styled.div<{ full?: boolean; maxHeight?: string }>`
  width: auto;
  ${({ full }) => full && 'width: 100%'};
  position: relative;
  overflow-y: auto;
  max-height: ${({ maxHeight }) => maxHeight || '300px'};
`;

export const SelectDropdownCheckboxContainer = styled.div`
  width: 353px;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 30px 48px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
`;

export const SelectDropdownCheckboxButtons = styled.div`
  display: flex;
  justify-content: end;
  padding: 5px;
`;

export const InputSelectCheckboxOptions = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  border: none;
  background: #ffffff;
  &:focus,
  &:hover {
    background: ${({ theme }) => theme.default.colors.buttons.blackGray7};
    img {
      background: #ffffff !important;
    }
  }
  &:active {
    background: ${({ theme }) => theme.default.colors.buttons.blackGray6};
  }
  border-bottom: 1px solid #f2f2f7;
`;
