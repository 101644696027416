export const certifications = {
  EJPT: { short_name: 'eJPT', name: 'Junior Penetration Tester', label: 'eJPT' },
  ICCA: { short_name: 'ICCA', name: 'INE Certified Cloud Associate', label: 'ICCA' },
  ECPPT: { short_name: 'eCPPT', name: 'Certified Professional Penetration Tester', label: 'eCPPT' },
  EWPT: { short_name: 'eWPT', name: 'Web Application Penetration Tester', label: 'eWPT' },
  MCIT: { short_name: 'MCIT', name: 'MCIT Jr Data Scientist', label: 'MCIT' },
  EWPTX: {
    short_name: 'eWPTX',
    name: 'Web Application Penetration Tester eXtreme',
    label: 'eWPTX',
  },
  ECDFP: { short_name: 'eCDFP', name: 'Certified Digital Forensics Professional', label: 'eCDFP' },
  ECTHP: { short_name: 'eCTHP', name: 'Certified Threat Hunting Professional', label: 'eCTHP' },
  ECRE: { short_name: 'eCRE', name: 'eLearnSecurity Certified Reverse Engineer', label: 'eCRE' },
  ENDP: { short_name: 'eNDP', name: 'eLearnSecurity Network Defense Professional', label: 'eNDP' },
  ECIR: { short_name: 'eCIR', name: 'Certified Incident Responder', label: 'eCIR' },
  EWDP: { short_name: 'eWDP', name: 'eLearnSecurity Web Defense Professional', label: 'eWDP' },
  ECPTX: {
    short_name: 'eCPTX',
    name: 'eLearnSecurity Certified Penetration Tester eXtreme',
    label: 'eCPTX',
  },
  EEDA: { short_name: 'eEDA', name: 'Enterprise Defense Administrator', label: 'eEDA' },
  ECMAP: {
    short_name: 'eCMAP',
    name: 'eLearnSecurity Certified Malware Analysis Professional',
    label: 'eCMAP',
  },
  ECXD: { short_name: 'eCXD', name: 'eLearnSecurity Certified eXploit Developer', label: 'eCXD' },
  EMAPT: { short_name: 'eMAPT', name: 'Mobile Application Penetration Tester', label: 'eMAPT' },
  INE_CLOUD_FUNDAMENTALS_FOR_AZURE: {
    short_name: 'INE Cloud Fundamentals for Azure',
    name: 'INE Cloud Fundamentals for Azure',
    label: 'INE CF for Azure',
  },
  INE_CLOUD_FUNDAMENTALS_FOR_AWS: {
    name: 'INE Cloud Fundamentals for AWS',
    short_name: 'INE Cloud Fundamentals for AWS',
    label: 'INE CF for AWS',
  },
  CLOUD_FUNDAMENTALS_CERTIFICATION: {
    short_name: 'Cloud Fundamentals Certification',
    name: 'Cloud Fundamentals Certification',
    label: 'CFC',
  },
};

export const certificationStatus = {
  PASSED: {
    label: 'Passed',
    value: 'Passed',
    key: 'Passed',
    tooltip: 'This certification has been successfully completed and approved',
  },
  FAILED: {
    label: 'Failed',
    value: 'Failed',
    key: 'Failed',
    tooltip: 'This attempt at the certification was unsuccessful',
  },
};

export const CERTIFICATIONS_FAQ_URL =
  'https://learn.ine.com/product-updates/certification-dashboard-faqs';
