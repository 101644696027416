/* eslint-disable react/display-name */
import { ReactComponent as AlertCircleSvg } from 'assets/icons/alert-circle.svg';
import { ReactComponent as ArrowDownSvg } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ArrowRightShort } from 'assets/icons/arrow-right-short.svg';
import { ReactComponent as ArrowUpSvg } from 'assets/icons/arrow-up.svg';
import { ReactComponent as AwardSvg } from 'assets/icons/award.svg';
import { ReactComponent as BarChart } from 'assets/icons/bar-chart.svg';
import { ReactComponent as BellSvg } from 'assets/icons/bell.svg';
import { ReactComponent as Block } from 'assets/icons/block.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as Cancel2 } from 'assets/icons/cancel2.svg';
import { ReactComponent as CancelSvg } from 'assets/icons/cancel.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Check2 } from 'assets/icons/check2.svg';
import { ReactComponent as Check3 } from 'assets/icons/check3.svg';
import { ReactComponent as Checked } from 'assets/icons/checked-icon.svg';
import { ReactComponent as ChevronDownPurpleSvg } from 'assets/icons/chevron-down-purple.svg';
import { ReactComponent as ChevronDownSvg } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronRightSvg } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ChevronUpPurpleSvg } from 'assets/icons/chevron-up-purple.svg';
import { ReactComponent as ChevronUpSvg } from 'assets/icons/chevron-up.svg';
import { ReactComponent as CircledCloseSvg } from 'assets/icons/circled-close.svg';
import { ReactComponent as CloseSvg } from 'assets/icons/close.svg';
import { ReactComponent as CopySvg } from 'assets/icons/copy.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Duplicate } from 'assets/icons/duplicate.svg';
import { ReactComponent as Edit2 } from 'assets/icons/edit2.svg';
import { ReactComponent as EditSvg } from 'assets/icons/edit.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';
import { ReactComponent as FilterSvg } from 'assets/icons/filter.svg';
import { ReactComponent as HamburgerMenuSvg } from 'assets/icons/hamburger-menu.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Informational } from 'assets/icons/informational.svg';
import { ReactComponent as Key } from 'assets/icons/key.svg';
import { ReactComponent as Lab } from 'assets/icons/lab.svg';
import { ReactComponent as LineChart } from 'assets/icons/line-chart-icon.svg';
import { ReactComponent as Link } from 'assets/icons/link.svg';
import { ReactComponent as Loading } from 'assets/icons/loading.svg';
import { ReactComponent as MenuBarsSvg } from 'assets/icons/menu-bars.svg';
import { ReactComponent as MenuConfigSvg } from 'assets/icons/menu-config.svg';
import { ReactComponent as MenuPieSvg } from 'assets/icons/menu-pie.svg';
import { ReactComponent as MenuUsersSvg } from 'assets/icons/menu-users.svg';
import { ReactComponent as MenuUserSvg } from 'assets/icons/menu-user.svg';
import { ReactComponent as MinusNumeric } from 'assets/icons/minus-numeric.svg';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as PlusNumeric } from 'assets/icons/plus-numeric.svg';
import { ReactComponent as Refresh } from 'assets/icons/refresh.svg';
import { ReactComponent as RepeatSvg } from 'assets/icons/repeat.svg';
import { ReactComponent as ResendSvg } from 'assets/icons/resend.svg';
import { ReactComponent as Save } from 'assets/icons/save.svg';
import { ReactComponent as SearchSvg } from 'assets/icons/search.svg';
import { ReactComponent as Sort } from 'assets/icons/sort.svg';
import { ReactComponent as SortDown } from 'assets/icons/sortDown.svg';
import { ReactComponent as StatusCompletedSvg } from 'assets/icons/status-completed.svg';
import { ReactComponent as StatusErrorSvg } from 'assets/icons/status-error.svg';
import { ReactComponent as StatusErrorGraySvg } from 'assets/icons/status-error-gray.svg';
import { ReactComponent as StatusPendingSvg } from 'assets/icons/status-pending.svg';
import { ReactComponent as TrashSvg } from 'assets/icons/trash.svg';
import { ReactComponent as Upload } from 'assets/icons/upload.svg';
import { ReactComponent as UserPlus } from 'assets/icons/user-plus.svg';
import { ReactComponent as UserMinus } from 'assets/icons/user-minus.svg';
import { ReactComponent as Clip } from 'assets/icons/clip.svg';
import { ReactComponent as Times } from 'assets/icons/times.svg';
import { ReactComponent as RedTeam } from 'assets/icons/assessments/red_team.svg';
import { ReactComponent as BlueTeam } from 'assets/icons/assessments/blue_team.svg';
import { ReactComponent as YellowTeam } from 'assets/icons/assessments/yellow_team.svg';
import { ReactComponent as Envelope } from 'assets/icons/envelope.svg';
import { ReactComponent as CheckGreen } from 'assets/icons/check-green.svg';
import { ReactComponent as Important } from 'assets/icons/important.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';

import { SVGProps } from 'react';

export const ICONS = {
  AlertCircle: (props: SVGProps<SVGSVGElement>): JSX.Element => <AlertCircleSvg {...props} />,
  ArrowDown: (props: SVGProps<SVGSVGElement>): JSX.Element => <ArrowDownSvg {...props} />,
  ArrowLeft: (props: SVGProps<SVGSVGElement>): JSX.Element => <ArrowLeftSvg {...props} />,
  ArrowRight: (props: SVGProps<SVGSVGElement>): JSX.Element => <ArrowRight {...props} />,
  ArrowRightShort: (props: SVGProps<SVGSVGElement>): JSX.Element => <ArrowRightShort {...props} />,
  ArrowUp: (props: SVGProps<SVGSVGElement>): JSX.Element => <ArrowUpSvg {...props} />,
  Award: (props: SVGProps<SVGSVGElement>): JSX.Element => <AwardSvg {...props} />,
  BarChart: (props: SVGProps<SVGSVGElement>): JSX.Element => <BarChart {...props} />,
  Bell: (props: SVGProps<SVGSVGElement>): JSX.Element => <BellSvg {...props} />,
  Block: (props: SVGProps<SVGSVGElement>): JSX.Element => <Block {...props} />,
  Calendar: (props: SVGProps<SVGSVGElement>): JSX.Element => <Calendar {...props} />,
  Cancel: (props: SVGProps<SVGSVGElement>): JSX.Element => <CancelSvg {...props} />,
  Cancel2: (props: SVGProps<SVGSVGElement>): JSX.Element => <Cancel2 {...props} />,
  Check: (props: SVGProps<SVGSVGElement>): JSX.Element => <Check {...props} />,
  Check2: (props: SVGProps<SVGSVGElement>): JSX.Element => <Check2 {...props} />,
  Check3: (props: SVGProps<SVGSVGElement>): JSX.Element => <Check3 {...props} />,
  Checked: (props: SVGProps<SVGSVGElement>): JSX.Element => <Checked {...props} />,
  ChevronDown: (props: SVGProps<SVGSVGElement>): JSX.Element => <ChevronDownSvg {...props} />,
  ChevronDownPurple: (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <ChevronDownPurpleSvg {...props} />
  ),
  ChevronRight: (props: SVGProps<SVGSVGElement>): JSX.Element => <ChevronRightSvg {...props} />,
  ChevronUp: (props: SVGProps<SVGSVGElement>): JSX.Element => <ChevronUpSvg {...props} />,
  ChevronUpPurple: (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <ChevronUpPurpleSvg {...props} />
  ),
  CircledClose: (props: SVGProps<SVGSVGElement>): JSX.Element => <CircledCloseSvg {...props} />,
  Clip: (props: SVGProps<SVGSVGElement>): JSX.Element => <Clip {...props} />,
  Clock: (props: SVGProps<SVGSVGElement>): JSX.Element => <Clock {...props} />,
  Close: (props: SVGProps<SVGSVGElement>): JSX.Element => <CloseSvg {...props} />,
  Copy: (props: SVGProps<SVGSVGElement>): JSX.Element => <CopySvg {...props} />,
  Download: (props: SVGProps<SVGSVGElement>): JSX.Element => <Download {...props} />,
  Duplicate: (props: SVGProps<SVGSVGElement>): JSX.Element => <Duplicate {...props} />,
  Edit: (props: SVGProps<SVGSVGElement>): JSX.Element => <EditSvg {...props} />,
  Edit2: (props: SVGProps<SVGSVGElement>): JSX.Element => <Edit2 {...props} />,
  Envelope: (props: SVGProps<SVGSVGElement>): JSX.Element => <Envelope {...props} />,
  ExternalLink: (props: SVGProps<SVGSVGElement>): JSX.Element => <ExternalLink {...props} />,
  Filter: (props: SVGProps<SVGSVGElement>): JSX.Element => <FilterSvg {...props} />,
  HamburgerMenu: (props: SVGProps<SVGSVGElement>): JSX.Element => <HamburgerMenuSvg {...props} />,
  Info: (props: SVGProps<SVGSVGElement>): JSX.Element => <Info {...props} />,
  Informational: (props: SVGProps<SVGSVGElement>): JSX.Element => <Informational {...props} />,
  Important: (props: SVGProps<SVGSVGElement>): JSX.Element => <Important {...props} />,
  Key: (props: SVGProps<SVGSVGElement>): JSX.Element => <Key {...props} />,
  Lab: (props: SVGProps<SVGSVGElement>): JSX.Element => <Lab {...props} />,
  LineChart: (props: SVGProps<SVGSVGElement>): JSX.Element => <LineChart {...props} />,
  Link: (props: SVGProps<SVGSVGElement>): JSX.Element => <Link {...props} />,
  Loading: (props: SVGProps<SVGSVGElement>): JSX.Element => <Loading {...props} />,
  MenuBars: (props: SVGProps<SVGSVGElement>): JSX.Element => <MenuBarsSvg {...props} />,
  MenuConfig: (props: SVGProps<SVGSVGElement>): JSX.Element => <MenuConfigSvg {...props} />,
  MenuPie: (props: SVGProps<SVGSVGElement>): JSX.Element => <MenuPieSvg {...props} />,
  MenuUser: (props: SVGProps<SVGSVGElement>): JSX.Element => <MenuUserSvg {...props} />,
  MenuUsers: (props: SVGProps<SVGSVGElement>): JSX.Element => <MenuUsersSvg {...props} />,
  MinusNumeric: (props: SVGProps<SVGSVGElement>): JSX.Element => <MinusNumeric {...props} />,
  Play: (props: SVGProps<SVGSVGElement>): JSX.Element => <Play {...props} />,
  Plus: (props: SVGProps<SVGSVGElement>): JSX.Element => <Plus {...props} />,
  PlusNumeric: (props: SVGProps<SVGSVGElement>): JSX.Element => <PlusNumeric {...props} />,
  Refresh: (props: SVGProps<SVGSVGElement>): JSX.Element => <Refresh {...props} />,
  Repeat: (props: SVGProps<SVGSVGElement>): JSX.Element => <RepeatSvg {...props} />,
  Resend: (props: SVGProps<SVGSVGElement>): JSX.Element => <ResendSvg {...props} />,
  Save: (props: SVGProps<SVGSVGElement>): JSX.Element => <Save {...props} />,
  Search: (props: SVGProps<SVGSVGElement>): JSX.Element => <SearchSvg {...props} />,
  Sort: (props: SVGProps<SVGSVGElement>): JSX.Element => <Sort {...props} />,
  SortDown: (props: SVGProps<SVGSVGElement>): JSX.Element => <SortDown {...props} />,
  StatusCompleted: (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <StatusCompletedSvg {...props} />
  ),
  StatusError: (props: SVGProps<SVGSVGElement>): JSX.Element => <StatusErrorSvg {...props} />,
  StatusErrorGray: (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <StatusErrorGraySvg {...props} />
  ),
  StatusPending: (props: SVGProps<SVGSVGElement>): JSX.Element => <StatusPendingSvg {...props} />,
  Trash: (props: SVGProps<SVGSVGElement>): JSX.Element => <TrashSvg {...props} />,
  CheckGreen: (props: SVGProps<SVGSVGElement>): JSX.Element => <CheckGreen {...props} />,
  Upload: (props: SVGProps<SVGSVGElement>): JSX.Element => <Upload {...props} />,
  UserPlus: (props: SVGProps<SVGSVGElement>): JSX.Element => <UserPlus {...props} />,
  UserMinus: (props: SVGProps<SVGSVGElement>): JSX.Element => <UserMinus {...props} />,
  Times: (props: SVGProps<SVGSVGElement>): JSX.Element => <Times {...props} />,
  RedTeam: (props: SVGProps<SVGSVGElement>): JSX.Element => <RedTeam {...props} />,
  BlueTeam: (props: SVGProps<SVGSVGElement>): JSX.Element => <BlueTeam {...props} />,
  YellowTeam: (props: SVGProps<SVGSVGElement>): JSX.Element => <YellowTeam {...props} />,
};

export const SIZES = {
  smaller: { width: '12px', height: '12px' },
  small: { width: '16px', height: '16px' },
  medium: { width: '18px', height: '18px' },
  default: { width: '24px', height: '24px' },
  large: { width: '36px', height: '36px' },
  largest: { width: '48px', height: '48px' },
};

export interface IIconProps extends SVGProps<SVGSVGElement> {
  icon: keyof typeof ICONS;
  color?: string;
  size?: keyof typeof SIZES | string;
  fill?: string;
  stroke?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Icon: React.FC<IIconProps> = ({
  icon,
  color,
  fill = 'none',
  size = 'default',
  stroke,
  style,
  ...rest
}): JSX.Element => {
  const $size = SIZES[size];
  const $color = { color };
  const Component = ICONS[icon];

  return (
    <Component
      stroke={stroke}
      fill={fill}
      style={{
        display: 'flex',
        justifyContent: 'center',
        ...$size,
        ...$color,
        ...style,
      }}
      {...rest}
    />
  );
};

export default Icon;
